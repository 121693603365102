import React from 'react'
import NowShowing from '../../components/NowShowing/NowShowing'
import Theater from '../Theater/Theater'

function HomePage() {
  return (
    <div>
       <NowShowing/>
    </div>
  )
}

export default HomePage